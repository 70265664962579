import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

function Project({ project, expandedStates, handleExpandClick }) {
    const tagsRef = useRef(null);
    const [isTagsOverflowing, setIsTagsOverflowing] = useState(false);
    
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    
    useEffect(() => {
        if (tagsRef.current.scrollHeight > tagsRef.current.clientHeight) {
            setIsTagsOverflowing(true);
        }
    }, [project]);
    
    return (
        <div key={project.id} className="project">
            {project.url 
                ? <a href={project.url} target="_blank" rel="noopener noreferrer">
                    <div className="project-image" style={{ backgroundImage: `url(${project.img})` }}></div>
                </a> 
                : <div className="project-image" style={{ backgroundImage: `url(${project.img})` }}></div>
            }
            <div className="project-details">
                <div className="project-section">
                    <h2 className={`project-title`} ref={titleRef} dangerouslySetInnerHTML={{ __html: project.title }}></h2>
                </div>
                <div className="project-section">
                    <p className={`project-description`} ref={descriptionRef} dangerouslySetInnerHTML={{ __html: project.description }}></p>
                </div>
                <div className="project-section project-section-tags">
                    <div className={`project-tags ${expandedStates[project.id] ? 'expanded' : ''}`} ref={tagsRef}>
                    {project.tags.map((tag, index) => (
                        <span key={index} className="tag">{tag}</span>
                    ))}
                    </div>
                    {isTagsOverflowing && !expandedStates[project.id] && project.id !== 7 && <span className="btnExpand"  onClick={() => handleExpandClick(project.id)}><FontAwesomeIcon icon={faPlus} /></span>}
                    {expandedStates[project.id] && <span className="btnExpand"  onClick={() => handleExpandClick(project.id)}><FontAwesomeIcon icon={faMinus} /></span>}
                </div>
            </div>
        </div>
    );
}
        
export default Project;