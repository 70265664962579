// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import HelloWorld from './components/HelloWorld';
import Resume from './components/resume/Resume';
import Articles from './components/articles/Articles';
import About from './components/about/About';
import Projects from './components/projects/Projects';
import Contact from './components/contact/Contact';

import ReactGA from 'react-ga';

ReactGA.initialize('G-575BM4J1JM'); // substitua por seu ID de acompanhamento

const Analytics = () => {
    const location = useLocation();
  
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);
  
    return null;
};


const App = () => {
   useEffect(() => {
        // Ping the backend
        fetch(`${process.env.REACT_APP_BACKEND_URL}/ping`)
        .then(response => response.json())
        .then(data => {
            if (data.pong) {
                console.log('Backend is awake!');
            }
        })
        .catch(error => {
            console.error('Error pinging the backend:', error);
        });
    }, []);

    return (
        <Router>
            <Analytics />
            <Routes>
                <Route exact path="/" element={<HelloWorld />} />
                <Route path="/about" element={<About />} />
                {/* Nem todos os que vagueiam estão perdidos */}
                <Route path="/resume" element={<Resume />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/articles" element={<Articles />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </Router>
    );
}

export default App;
