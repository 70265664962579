import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './Projects.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Project from './Project';

import biakaipper from '../assets/biakaipper.png';
import andersondemedeiros from '../assets/andersondemedeiros.jpg';
import rioblockchain from '../assets/rioblockchain.png';
import agora from '../assets/agora.png';
import mitmais from '../assets/mitmais.png';
import blackdracos from '../assets/blackdracos.png';
import ubilong from '../assets/ubilong.png';
import chatbsv from '../assets/chatbsv.jpeg';

function Projects() {
    const [expandedStates, setExpandedStates] = useState({});

    const handleExpandClick = (projectId) => {
        setExpandedStates({
          ...expandedStates,
          [projectId]: !expandedStates[projectId]
        });
    };

    const projects = [
        {
            id: 1,
            img: biakaipper,
            title: '<a href="https://biakaipper.com/" target="_blank" rel="noopener noreferrer">Bia Kaipper</a>',
            description: 'Developed the official website for composer and performer Bia Kaipper, featuring seamless music integration, an interactive media player, extensive photo galleries, and robust social media connectivity for enhanced fan engagement.',
            tags: ['WordPress', 'PHP', 'JavaScript', 'CSS', 'HTML', 'Custom Theme Development', 'Media Integration (YouTube)', 'Media Integration (Music Player)', 'Social Media Integration'],
            url: 'https://biakaipper.com/',
        },
        {
            id: 2,
            img: andersondemedeiros,
            title: 'Anderson de Medeiros',
            description: 'Crafted a visually striking website for photographer Anderson de Medeiros, featuring high-definition, full-splash photo galleries, a customized carousel, and an intuitive user interface for an immersive viewing experience.',
            tags: ['WordPress', 'PHP', 'JavaScript', 'CSS', 'HTML', 'Custom Theme Development', 'High-Definition Photo Gallery', 'Customized Photo Carousel'],
        },
        {
            id: 3,
            img: rioblockchain,
            title: '<a href="https://rioblockchain.com.br/" target="_blank" rel="noopener noreferrer">RioBlockchain</a>',
            description: "Managed RioBlockchain's infrastructure on Google Cloud, orchestrating blockchain nodes, developing Python programs, and developed front-end customization for enhanced user experience.",
            tags: ['Google Cloud Platform', 'Linux', 'BSV Blockchain', 'BCH Blockchain', 'BTC Blockchain', 'Python', 'Node.js', 'Shell Scripting', 'JavaScript', 'WordPress'],
            url: 'https://rioblockchain.com.br/',
        },
        {
            id: 4,
            img: agora,
            title: '<a href="https://agora.icu/" target="_blank" rel="noopener noreferrer">Agora</a>',
            description: "Contributing to Agora, a dynamic platform leveraging BSV blockchain, I've been instrumental in evolving solutions including custom authentication, NFT generation, and enhancing its digital economy.",
            tags: ['Next.js', 'React', 'JavaScript', 'BSV Blockchain', 'MoneyButton', 'HandCash', 'NFTs', 'Custom Hierarchical Wallet'],
            url: 'https://agora.icu/',
        },
        {
            id: 5,
            img: mitmais,
            title: '<a href="https://mitmais.org/" target="_blank" rel="noopener noreferrer">MIT+</a>',
            description: "Developed the MIT+ platform, a digital showcase for the MITsp's national theatrical productions, featuring custom navigation, authentication, and search functionalities using Frontity and WordPress, <a href='https://guia.folha.uol.com.br/teatro/2021/11/mostra-de-teatro-online-gratuita-com-curadoria-do-mitsp-marca-agenda-teatral-de-dezembro.shtml' target='_blank' rel='noopener noreferrer'>highlighted in major media</a>.",
            tags: ['Frontity, React', 'JavaScript', 'CSS', 'HTML', 'WordPress', 'Custom Plugin Development', 'Authentication Systems', 'Custom Navigation', 'Advanced Search'],
            url: 'https://mitmais.org/',
        },
        {
            id: 6,
            img: blackdracos,
            title: 'Blackdracos',
            description: "Developed BlackDracos, creating NFTs for artworks with a Metamask-integrated portal, MATIC network sales platform with Venly marketplace, and contributing to an Unreal Engine virtual museum.",
            tags: ['NFTs', 'Blockchain Integration', 'React', 'JavaScript', 'CSS', 'HTML', 'MATIC Network', 'Venly Marketplace', 'Metamask Wallet Integration', 'Unreal Engine'],
        },
        {
            id: 7,
            img: ubilong,
            title: '<a href="https://ubilong.com/" target="_blank" rel="noopener noreferrer">Ubilong</a>',
            description: "Contributed to Ubilong, a platform for licensed collectibles, enabling users to support favorite artists and acquire exclusive content, by developing the user authentication system and integrating PayPal for secure transactions.",
            tags: ['Node.js', 'PayPal Integration', 'User Authentication'],
            url: 'https://ubilong.com/',
        },
        {
            id: 8,
            img: chatbsv,
            title: '<a href="https://chatbsv.com/" target="_blank" rel="noopener noreferrer">ChatBSV</a>',
            description: "Actively contributing to <a href='https://coingeek.com/talking-ai-and-blockchain-with-chatbsv-i-was-surprised-nobody-tried-to-do-that-yet/' target='_blank' rel='noopener noreferrer'>ChatBSV</a>, integrating AI services like ChatGPT and DALL-E with BSV blockchain for microtransactions, enhancing user interaction through HandCash wallet integration.",
            tags: ['BSV Blockchain', 'Next.js', 'HandCash Integration', 'AI Integration', 'Microtransaction Management'],
            url: 'https://chatbsv.com/',
        },
    ];

    return (
        <div className="outer-div">
            <Helmet>
                <title>esthon.com - Projects</title>
            </Helmet>
        <div className="content-projects">
            <div className="back-link back-link-contact">
                <Link to="/about"><FontAwesomeIcon icon={faArrowLeft} /></Link>
            </div>
            <h1>Projects</h1>
            <p>
                Below, you'll find a selection of projects I've had the privilege to work on in the past years. Please note that not all of my projects are listed here. Some are omitted due to client preferences, others may not have reached completion for various reasons.
            </p>
            <p>In the fast-paced and ever-evolving world of technology, it's not uncommon for projects to change direction or not come to fruition. Thank you for your understanding.</p>
            <div className='project-list'>
            {[...projects].sort((a, b) => b.id - a.id).map((project) => (
                <Project project={project} expandedStates={expandedStates} handleExpandClick={handleExpandClick} />
            ))}
                <div className="back-link-bottom back-link-bottom-proj only-mobile">
                    <Link to="/about"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Projects;