import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import DefaultHelmet from '../DefaultHelmet';

import { Link } from 'react-router-dom';
import './Articles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function Articles() {
  const [data, setData] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const articleRef = useRef();
  const formatArticle = useRef(null);

  useEffect(() => {
    console.log(process.env.REACT_APP_BACKEND_URL)
    fetch(`${process.env.REACT_APP_BACKEND_URL}/get-articles`)
      .then(response => response.json())
      .then(data => setData(data));
  }, []);

  useEffect(() => {
    if (articleRef.current) {
      // adicionando classe para estilização
      const elementTypes = ['h1', 'h3', 'h4', 'li', 'figcaption', 'figure', 'p', 'img', 'menu', 'ol', 'ul'];

      elementTypes.forEach((elementType) => {
        const elements = articleRef.current.getElementsByTagName(elementType);
        for (let i = 0; i < elements.length; i++) {
          // adicionando classe para estilização
          elements[i].className += ` articles-${elementType}`;
        }
      });
        
      formatArticle.current = setTimeout(() => {
        // verificando e redimensionando imagens
        const images = articleRef.current.getElementsByTagName('img');
        for (let i = 0; i < images.length; i++) {
          
          // images[i].className += ' articles-img';
          // if (images[i].naturalWidth > 500) {
          //   images[i].style.cursor = 'zoom-in';
          //   images[i].style.overflowWrap = 'break-word';
          //   images[i].style.wordBreak = 'break-word';
          //   images[i].style.width = '100%';
          // }
          if (images[i].naturalWidth < 100) {
            images[i].style.cursor = '';
            images[i].style.overflowWrap = '';
            images[i].style.wordBreak = '';
            images[i].style.width = '86px';
          }
        }

        // adicionando propriedades para abrir links externos em nova aba
        const links = articleRef.current.getElementsByTagName('a');
        for (let i = 0; i < links.length; i++) {
          if (!links[i].href.includes('esthon.com')) {
            links[i].target = '_blank';
            links[i].rel = 'noopener noreferrer';
          }
        }
      }, 5000);
    }
    // Limpar o timeout quando o componente for desmontado ou quando selectedArticle mudar
    return () => clearTimeout(formatArticle.current);
  }, [selectedArticle]);

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    window.scrollTo(0, 0); // Rola para o topo da página
  };

  const handleToggleArticles = () => {
    setSelectedArticle(null);
    window.scrollTo(0, 0); // Rola para o topo da página
  }

  return (
    <div className="outer-div">
      <DefaultHelmet />
      <Helmet>
                <title>esthon.com - Articles</title>
      </Helmet>
      <div className="content-articles">
        {data ? (
          <div>
            {!selectedArticle ?
            (
              <>
              <div className="back-link">
                <Link to="/about"><FontAwesomeIcon icon={faArrowLeft} /></Link>
              </div>
              <h1 className="articles-h1">Articles</h1>
              <p className="content-articles-p" style={{ marginTop: '20px'}}>Over time, in my journey of continuous learning, as I've been noting down so many things, and insights, I decided to turn some of those notes into articles. The goal is to explore topics from the basics to more advanced concepts.</p>
              <p className="content-articles-p">This initiative is, first and foremost, a way to organize my thoughts and deepen my own understanding. However, it's also an invitation to you,  to embark on this journey of discovery with me.</p>
              <p style={{ marginTop: '32px', textAlign: 'center'}}>Explore. Delve deeper. Discover.</p>
              {data.map((article, index) => {
                // Extrai o primeiro parágrafo
                const firstParagraph = article['content:encoded'].match(/<p>(.*?)<\/p>/)[1];
                // Remove tags HTML
                const textOnly = firstParagraph.replace(/<[^>]*>/g, '');
                // Divide o parágrafo em palavras e pega as primeiras 20
                const excerpt = textOnly.split(' ').slice(0, 20).join(' ');
                return (
                  <div className="article-snippet" key={index} onClick={() => handleArticleClick(article)}>
                    <h3><a>{article.title}</a></h3>
                    <p><a dangerouslySetInnerHTML={{ __html: excerpt + '...' }} /></p>
                    <span>Published on {new Date(article.isoDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</span>
                  </div>
                );
              }
              )}
              </>
            )
            :
            (
              <>
              <div className="back-link">
                  <span onClick={() => handleToggleArticles()}><FontAwesomeIcon icon={faArrowLeft} /></span>
              </div>
                <a href={selectedArticle.link} target="_blank" rel="noopener noreferrer"><h1 className="article-h1">{selectedArticle.title}</h1></a>
                <span className="author">
                  Written by <a href="https://medium.com/@esthon" target="_blank" rel="noopener noreferrer">{selectedArticle.creator}</a><br />
                </span>
                <span>
                  Published on {new Date(selectedArticle.isoDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} <a className="visit-icon" href={selectedArticle.link} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                </span>
                
                <div ref={articleRef} dangerouslySetInnerHTML={{ __html: selectedArticle['content:encoded'] }} />
                <span className="visit">
                  <a href={selectedArticle.link} target="_blank" rel="noopener noreferrer">
                  Visit original article <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                </span>
                <div className="back-link-bottom only-mobile">
                  <span onClick={() => handleToggleArticles()}><FontAwesomeIcon icon={faArrowLeft} /></span>
                </div>
              </>
            )}
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    </div>
  );
}

export default Articles;