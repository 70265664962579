import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import DefaultHelmet from '../DefaultHelmet';

import { Link } from 'react-router-dom';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from "react-google-recaptcha";
import { glitchEffect } from '../../lib/glitchEffect';

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [btnMessage, setBtnMessage] = useState('Send');
    const [hover, setHover] = useState(false);
    const [glitchedRabbit, setGlitchedRabbit] = useState(false);
    const [feedback, setFeedback] = useState(null);
    const [feedbackType, setFeedbackType] = useState(null);
    const [showRecaptcha, setShowRecaptcha] = useState(true);

    useEffect(() => {
        const originalMessage = 'Send';
        const clearIntervals = glitchEffect(setBtnMessage, setGlitchedRabbit, originalMessage);

        return () => {
            clearIntervals();
        };
    }, []);

    const onChange = (value) => {
        setRecaptchaValue(value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
      
        fetch(`${process.env.REACT_APP_BACKEND_URL}/contact-esthon`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, email, message, recaptchaValue }),
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setFeedback('Message sent successfully!');
            setFeedbackType('success');
            setName('');
            setEmail('');
            setMessage('');
          } else {
            setFeedback('Error sending message. Try again.');
            setFeedbackType('error');
          }
          setShowRecaptcha(false);
          setTimeout(() => {
            setFeedback(null);
            setFeedbackType(null);
            setShowRecaptcha(true);
          }, 5000); // hide feedback and show recaptcha after 5 seconds
        })
        .catch(error => {
          setFeedback('Error sending message. Try again.');
          setShowRecaptcha(false);
          setFeedbackType('error');
          setTimeout(() => {
            setFeedback(null);
            setFeedbackType(null);
            setShowRecaptcha(true);
          }, 5000); // hide feedback and show recaptcha after 5 seconds
        });
    };

    return (
        <div className="outer-div">
            <DefaultHelmet />
            <Helmet>
                <title>esthon.com - Contact</title>
            </Helmet>
          <div className="content-contact">            
            <div className="back-link back-link-contact">
              <Link to="/about"><FontAwesomeIcon icon={faArrowLeft} /></Link>
            </div>
            <h1>Contact</h1>
            <p>Uncover the potential of our collaboration. Venture to reach out.</p>
            <form onSubmit={handleSubmit}>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" required />
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
              <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Message" required />
              {showRecaptcha ? (
                <div className="recaptcha">
                  <ReCAPTCHA sitekey="6Lc7c0YUAAAAAMjGbVW5EUK8Sh2mwoxnCYDvz3z3" onChange={onChange} />
                </div>
              ) : (
                <div className={`feedback ${feedbackType}`}>{feedback}</div>
              )}
              <button type="submit">{btnMessage}</button>
            </form>
          </div>
        </div>
    );
}

export default Contact;