import React from 'react';

function Languages(props) {
    return (
      <div className="job-description">
        <h4 className="resume-h4">{props.language.title}</h4>
        <p>{props.language.description}</p>
      </div>
    );
}

export default Languages;