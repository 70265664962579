// lib/glitchEffect.js

const originalMessage = 'Hello world';
export const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890@#$%^&*()_+ÇýùéêšŽ¥óćĒƒï£×åßΩ≈øπ∆';
export const charsR = 'ア イ ウ エ オ カ キ ク ケ コ サ シ ス セ ソ タ チ ツ テ ト ナ ニ ヌ ネ ノ ハ ヒ フ ヘ ホ マ ミ ム メ モ ヤ ユ ヨ ラ リ ル レ ロ ワ ヲ ン 0 1 2 3 4 5 6 7 8 9';

export function glitchEffect(setMessage, setGlitchedRabbit, originalMessage) {
    let glitchInterval;
    const activateGlitch = () => {
        glitchInterval = setInterval(() => {
            let glitchedMessage = '';
            for (let i = 0; i < originalMessage.length; i++) {
                if (Math.random() < 0.1) {
                    glitchedMessage += chars[Math.floor(Math.random() * chars.length)];
                } else {
                    glitchedMessage += originalMessage[i];
                }
            }
            setMessage(glitchedMessage);
        }, 30);
    };

    if (setGlitchedRabbit) {
        setInterval(() => {
            for (let i = 0; i < originalMessage.length; i++) {
                if (Math.random() < 0.1) {
                    setGlitchedRabbit(false);
                } else {
                    setGlitchedRabbit(true);
                }
            }
        }, 60);
    }

    const timer = setInterval(() => {
        activateGlitch();
        setMessage(originalMessage);
        setTimeout(() => {
            clearInterval(glitchInterval);
            setMessage(originalMessage);
        }, 500);
    }, 1500);

    return () => {
        clearInterval(timer);
        clearInterval(glitchInterval);
    };
}