import React from 'react';

function Education(props) {
    return (
      <div className="job-description">
        <h4 className="resume-h4">{props.education.title}</h4>
        <p>{props.education.duration}</p>
        <ul>
          {props.education.details.map((detail, index) => <li key={index}>{detail}</li>)}
        </ul>
      </div>
    );
}

export default Education;