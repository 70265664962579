import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './About.css';
import DefaultHelmet from '../DefaultHelmet';


import { glitchEffect } from '../../lib/glitchEffect';

function About() {
    const navItemsRef = useRef([]);
    const [message, setMessage] = useState(null);

    const [glitchingLi, setGlitchingLi] = useState(null);
    const [originalMessages, setOriginalMessages] = useState(['Experience', 'Projects', 'Articles', 'Contact']);

    const [glitchState, setGlitchState] = useState({ glitchingLi: null, originalMessage: null });

    
    const lastRandomIndexRef = useRef(null);

    const applyGlitchEffectToRandomLi = () => {
        // Limpar o efeito anterior e restaurar a mensagem original
        // clearGlitchEffect();

        let randomIndex;
        do {
            randomIndex = Math.floor(Math.random() * navItemsRef.current.length);
        } while (randomIndex === lastRandomIndexRef.current);

        lastRandomIndexRef.current = randomIndex;

        const randomLi = navItemsRef.current[randomIndex];

        if (randomLi) {
            const newOriginalMessage = randomLi.textContent;

            // Guardar o novo li e a nova mensagem original
            setGlitchState({ glitchingLi: randomLi, originalMessage: newOriginalMessage });

            const clearIntervals = glitchEffect(setMessage, null, newOriginalMessage);

            return clearIntervals;
        }
    };

    const clearGlitchEffect = () => {
        // console.log('clearGlitchEffect', originalMessages)

        navItemsRef.current.forEach((li, index) => {
            if (li && originalMessages[index]) {
                li.textContent = originalMessages[index];
            }
        });
    };

    useLayoutEffect(() => {
        // Atualize navItemsRef com os elementos li
        navItemsRef.current = document.querySelectorAll('li');

        // Verifique se navItemsRef.current contém elementos
        if (navItemsRef.current.length > 0) {
            Array.from(navItemsRef.current).forEach(li => {
                if (li) {
                    const width = li.getBoundingClientRect().width;
                    li.style.width = `${width}px`;
                }
            });
        }
    }, []);

    useEffect(() => {
        let clearPreviousEffect = applyGlitchEffectToRandomLi();
      
        const intervalId = setInterval(() => {
          // Limpar o efeito anterior e restaurar a mensagem original
          if (clearPreviousEffect) {
            clearPreviousEffect();
            if (glitchState.glitchingLi) {
              glitchState.glitchingLi.textContent = glitchState.originalMessage;
            }
          }
      
          // Aplicar um novo efeito e armazenar a função para limpar o efeito
          clearPreviousEffect = applyGlitchEffectToRandomLi();
        }, 2000); // Alterar o efeito a cada 2 segundos
      
        return () => {
          clearInterval(intervalId);
          if (clearPreviousEffect) {
            clearPreviousEffect();
          }
        };
    }, []);

    useEffect(() => {
        
        clearGlitchEffect();
        if (glitchState.glitchingLi && !originalMessages.includes(message)) {
            glitchState.glitchingLi.textContent = message;
        }
    }, [message]);

  return (
    <div className="outer-div">
        <DefaultHelmet />
        <Helmet>
            <title>esthon.com</title>
        </Helmet>
        <div className="content-about">
            <h1>Greetings... </h1>
            <br />
            <p>I'm Esthon Jr, a seasoned IT Engineer with a lifelong dedication to technology, specializing in Web Development, Blockchain, and AI. Passionate about innovating and transforming ideas into effective digital solutions. Committed to navigating the digital landscape with creativity and expertise, I aim to empower partners in achieving their goals.</p>
            <br />
            <nav>
                <ul>
                    <Link to="/resume"><li>Experience</li></Link>
                    <Link to="/projects"><li>Projects</li></Link>
                    <Link to="/articles"><li>Articles</li></Link>
                    <Link to="/contact"><li>Contact</li></Link>
                </ul>
            </nav>
        </div>        
    </div>
  );
}

export default About;