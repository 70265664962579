// components/res/Header.js
import React from 'react';
import Summary from './Summary';
// import qrcodeImage from '../../../public/res/qrcode_mej.solutions.png';


function Header(props) {
    return (
      <>
      <div className="header">
        <div className="header-left">
          <h1 className="resume-h1">{props.name}</h1>
          <h2 className="resume-h2">{props.title}</h2>
          <p>I’ve been involved with information technology my entire life, since the days of the TK-90 and MSX, not just as a hobby but as a defining career. In today's world, where endless streams of data are generated every day, I see information as an invaluable resource, with IT solutions serving as essential navigation tools in this vast digital landscape. I breathe life into ideas through technology, with expertise in areas such as Web Development, Blockchain, and AI, embracing challenges and committing to continuous learning and improvement, driven by an enduring passion for all things digital.</p>
          <br className="show-mobile" />
          <p className="show-mobile">My mission as an active agent in this process is to be an
          innovation conduit, applying my diverse skill set to help
          clients navigate the digital landscape, achieving their goals
          with creativity and effectiveness.</p>
        </div>
        <div className="header-right">
          <div className="contact-item">
            <img className="resume-img" src="/res/envelope-solid-16w.png" alt="Email" width="16" />
            <p>{props.email}</p>
          </div>
          {/* <div className="contact-item">
            <img className="resume-img" src="/res/phone-solid-16.png" alt="Phone" width="16" />
            <p>{props.phone}</p>
          </div> */}
          <div className="contact-item">
            <img className="resume-img" src="/res/location-dot-solid-16.png" alt="Location" width="16" />
            <div>
              <p>{props.location1}</p>
              <p>{props.location2}</p>
            </div>
          </div>
          <div className="header-links">
            <div className="contact-item">
              <img className="resume-img" src="/res/linkedin-in-16.png" alt="LinkedIn" width="16" />
              <p><a href={props.linkedin} target="_blank" rel="noopener noreferrer">{props.linkedin}</a></p>
            </div>
            <div className="contact-item">
              <img className="resume-img" src="/res/building-solid-16.png" alt="Website" width="16" />
              <p><a href={props.website1} target="_blank" rel="noopener noreferrer">{props.website1}</a></p>
            </div>
            <div className="contact-item">
              <img className="resume-img" src="/res/circle-user-solid-16.png" alt="Website" width="16" />
              <p><a href={props.website2}>{props.website2}</a></p>
            </div>
          </div>
          <a href={props.website1} target="_blank" rel="noopener noreferrer"><img src="/res/qrcode_mej.solutions.png" alt="qrcode mej.solutions" height={125} /></a>
          {/* <br />
          <p><b>Core Competencies:</b> JavaScript, Node.js, React, Blockchain Applications, AI Solutions, Python, Cloud-Based Platforms, Comprehensive Web Development, among others</p> */}
        </div>
      </div>
      <div className="header">
        <div className="header-left">
          <p className="not-mobile">My mission as an active agent in this process is to be an
          innovation conduit, applying my diverse skill set to help
          clients navigate the digital landscape, achieving their goals
          with creativity and effectiveness.</p>
        </div>
        <div className="header-right">
          <p><b>Core Competencies:</b> JavaScript, Node.js, React, Blockchain Applications, AI Solutions, Python, Cloud-Based Platforms, Comprehensive Web Development, among others</p>
        </div>
      </div>
      </>
    );
}
  
export default Header;