import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { glitchEffect } from '../lib/glitchEffect';
import { charsR } from '../lib/glitchEffect';
// import { chars } from '../lib/glitchEffect';
import DefaultHelmet from './DefaultHelmet';

import './HelloWorld.css';

// const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890@#$%^&*()_+';
// const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890@#$%^&*()_+ÇýùéêšŽ¥óćĒƒï£×åßΩ≈øπ∆';
// const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopqrstuvwxyz@#$%^&*()_+ÇýùéêšŽ¥óćĒƒï£×åßΩ≈øπ∆';
// const chars = 'アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲン0123456789'
// const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890@#$%^&*()_+›°ÇýùéêšŽ—¥‹óćĒ”ƒ¹ï³£®¬÷×åßΩ≈µøπ∆';
// const chars = 'ÇýùéêšŽ¥óćĒƒï£×åßΩ≈µøπ∆';
// const chars = '01'

const RabbitSVG = () => {
    return (
        <svg width="1.5em" height="1.5em" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" 
             viewBox="0 0 864 864">
        <path 
            fill="white" 
            stroke="black" 
            strokeWidth="40"
            d="M681.4,340.7c-8.1-12.4-20-20.9-33.7-26.2c-9.9-3.8-36.8-3.5-40.2-13.8c-1.4-4.2-0.9-8.7-0.6-13.1
            c3.2-41.9-10.3-84.8-37.1-117.2c-9.7-11.7-21.4-23-23.9-38c-0.6-3.6-0.7-7.5-2.4-10.7c-1.8-3.2-6.2-5.4-9.2-3.3
            c-1.4,1-2.2,2.7-2.9,4.3c-7.8,18.5-11.9,38.6-11.9,58.7c0,0.7,0,1.4-0.4,1.9c-2.6,4.1-19.6-29.7-21.5-33.2c-7.3-14-14.6-28-22-42
            c-2.4-4.6-8.1-9.9-11.9-6.3c-2.1,2.1-1.5,5.6-1.1,8.6c2.9,21-14.1,39.3-19,59.9c-2.3,9.8-1.9,20.1-0.6,30.1
            c5.5,42.2,27.9,77.7,55.3,109.4c5.7,6.6,12.2,14,11.2,22.6c-0.6,4.8-3.5,8.9-6,13c-4.9,8-8.6,16.8-11,25.9c-1.6,6-0.6,15.9-4.2,21
            c-3.2,4.6-13,8.1-17.8,11c-7.7,4.6-15.4,9.2-23.1,13.7c-66.8-37.9-133.3-31.7-194.7,15c-27.9,21.2-52.1,47.7-67.2,79.5
            c-7.4,15.5-12.5,32.1-15.3,49.1c-2.7,16.6,2,39.4-4.3,54.5c-7.6-3.6-17.1,0-22.7,6.3c-5.6,6.3-8.1,14.7-10.2,22.8
            c-1.8,7.2-3.3,14.5-2.9,21.9c0.9,13.6,8.5,25.8,17.2,36.3c7.7,9.4,16.8,18.1,28.1,22.6c11.8,4.7,23.9,3.2,34.5-3.5
            c19.3,19.1,46.7,32.2,73.8,35.8c21.2,2.9,42.8,1.8,64.2,3.2c26.6,1.7,53.9,7.2,79-4.3c5.9-2.7,10.9-8.2,11.3-14.6
            c0.2-3-0.6-5.9-1.4-8.7c-2-6.4-3.7-12.3-5.4-18.8c19.3-5.3,37.8-10.3,57.7-15.8c20.9,19.2,44.2,37.7,71.7,46.2
            c13.2,4,27,5.5,40.7,4.1c10.2-1,27.8,1.5,34.2-8.1c12-18-16.3-34.1-28.6-40.8c-22-11.9-32.8-35.8-9.5-53.7
            c7.3-5.6,15.7-11.6,16.9-20.6c4,2.7,8.9-2.3,10.8-6.7c4.5-10.1,6.9-21.1,7.2-32.1c1,2.5,2.9,4.6,5.3,5.8c5.2-22.5,4.7-46.4-1.7-68.6
            c-1.6-5.5-3.5-11.1-3.4-16.9c0.3-19.2,38.8-20.7,53.5-26.9c21.6-9.2,38.5-24.4,47.4-46.5c-1.5-4.9-2.3-12.8-6-19
            C712.8,389.5,697.2,365,681.4,340.7z"/>
        </svg>
    );
}

const RabbitSVG2 = () => {
    return (
        <svg width="1.5em" height="1.5em" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" 
             viewBox="0 0 864 864">
        <path 
            fill="white" 
            stroke="white" 
            strokeWidth="30"
            d="M681.4,340.7c-8.1-12.4-20-20.9-33.7-26.2c-9.9-3.8-36.8-3.5-40.2-13.8c-1.4-4.2-0.9-8.7-0.6-13.1
            c3.2-41.9-10.3-84.8-37.1-117.2c-9.7-11.7-21.4-23-23.9-38c-0.6-3.6-0.7-7.5-2.4-10.7c-1.8-3.2-6.2-5.4-9.2-3.3
            c-1.4,1-2.2,2.7-2.9,4.3c-7.8,18.5-11.9,38.6-11.9,58.7c0,0.7,0,1.4-0.4,1.9c-2.6,4.1-19.6-29.7-21.5-33.2c-7.3-14-14.6-28-22-42
            c-2.4-4.6-8.1-9.9-11.9-6.3c-2.1,2.1-1.5,5.6-1.1,8.6c2.9,21-14.1,39.3-19,59.9c-2.3,9.8-1.9,20.1-0.6,30.1
            c5.5,42.2,27.9,77.7,55.3,109.4c5.7,6.6,12.2,14,11.2,22.6c-0.6,4.8-3.5,8.9-6,13c-4.9,8-8.6,16.8-11,25.9c-1.6,6-0.6,15.9-4.2,21
            c-3.2,4.6-13,8.1-17.8,11c-7.7,4.6-15.4,9.2-23.1,13.7c-66.8-37.9-133.3-31.7-194.7,15c-27.9,21.2-52.1,47.7-67.2,79.5
            c-7.4,15.5-12.5,32.1-15.3,49.1c-2.7,16.6,2,39.4-4.3,54.5c-7.6-3.6-17.1,0-22.7,6.3c-5.6,6.3-8.1,14.7-10.2,22.8
            c-1.8,7.2-3.3,14.5-2.9,21.9c0.9,13.6,8.5,25.8,17.2,36.3c7.7,9.4,16.8,18.1,28.1,22.6c11.8,4.7,23.9,3.2,34.5-3.5
            c19.3,19.1,46.7,32.2,73.8,35.8c21.2,2.9,42.8,1.8,64.2,3.2c26.6,1.7,53.9,7.2,79-4.3c5.9-2.7,10.9-8.2,11.3-14.6
            c0.2-3-0.6-5.9-1.4-8.7c-2-6.4-3.7-12.3-5.4-18.8c19.3-5.3,37.8-10.3,57.7-15.8c20.9,19.2,44.2,37.7,71.7,46.2
            c13.2,4,27,5.5,40.7,4.1c10.2-1,27.8,1.5,34.2-8.1c12-18-16.3-34.1-28.6-40.8c-22-11.9-32.8-35.8-9.5-53.7
            c7.3-5.6,15.7-11.6,16.9-20.6c4,2.7,8.9-2.3,10.8-6.7c4.5-10.1,6.9-21.1,7.2-32.1c1,2.5,2.9,4.6,5.3,5.8c5.2-22.5,4.7-46.4-1.7-68.6
            c-1.6-5.5-3.5-11.1-3.4-16.9c0.3-19.2,38.8-20.7,53.5-26.9c21.6-9.2,38.5-24.4,47.4-46.5c-1.5-4.9-2.3-12.8-6-19
            C712.8,389.5,697.2,365,681.4,340.7z"/>
        </svg>
    );
}

const HelloWorld = () => {
    const [message, setMessage] = useState('Hello world');
    const [hover, setHover] = useState(false);
    const [glitchedRabbit, setGlitchedRabbit] = useState(false);


    useEffect(() => {
        const originalMessage = 'Hello world';
        const clearIntervals = glitchEffect(setMessage, setGlitchedRabbit, originalMessage);

        return () => {
            clearIntervals();
        };
    }, []);

    // const originalMessage = 'Hello world';
    // const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890@#$%^&*()_+ÇýùéêšŽ¥óćĒƒï£×åßΩ≈øπ∆';
    // const charsR = 'ア イ ウ エ オ カ キ ク ケ コ サ シ ス セ ソ タ チ ツ テ ト ナ ニ ヌ ネ ノ ハ ヒ フ ヘ ホ マ ミ ム メ モ ヤ ユ ヨ ラ リ ル レ ロ ワ ヲ ン 0 1 2 3 4 5 6 7 8 9';

    // useEffect(() => {
    //     let glitchInterval;
    //     const activateGlitch = () => {
    //         glitchInterval = setInterval(() => {
    //             let glitchedMessage = '';
    //             // let glitechedRabbit = '';
    //             for (let i = 0; i < originalMessage.length; i++) {
    //                 if (Math.random() < 0.1) {
    //                     glitchedMessage += chars[Math.floor(Math.random() * chars.length)];
    //                     // setGlitchedRabbit(false);
    //                 } else {
    //                     glitchedMessage += originalMessage[i];
    //                     // setGlitchedRabbit(true);
    //                 }
    //             }
    //             setMessage(glitchedMessage);
    //         }, 30);
    //     };
    //     setInterval(() => {
    //             for (let i = 0; i < originalMessage.length; i++) {
    //                 if (Math.random() < 0.1) {
    //                     setGlitchedRabbit(false);
    //                 } else {
    //                     setGlitchedRabbit(true);
    //                 }
    //             }
    //         }, 60);

    //     const timer = setInterval(() => {
    //         activateGlitch();
    //         setMessage(originalMessage);
    //         // setGlitchedRabbit(false);
    //         // setHover(true)
    //         // After x seconds, clear the glitch effect and reset the message
    //         setTimeout(() => {
    //             clearInterval(glitchInterval);
    //             setMessage(originalMessage);
    //             // setGlitchedRabbit(false);                
    //             // setHover(false)
    //         }, 500);
    //     }, 1500);

    //     return () => {
    //         clearInterval(timer);
    //         clearInterval(glitchInterval);
    //     };
    // }, []);

    const rabbitHover = () => {
        setHover(true);
        setTimeout(() => {
            setHover(false)
        }, 3000);
    }

    return (
        <div>
            <DefaultHelmet />
            <Helmet>
                <title>esthon.com</title>
                <meta property="og:title" content="esthon.com" />
                <meta property="og:description" content="Welcome to the digital realm of Esthon Jr, a seasoned IT Engineer specializing in Web Development, Blockchain, and AI. Discover projects, insights, and the journey of transforming ideas into effective digital solutions." />
                <meta property="og:image" content="https://esthon.com/og.png" />
                <meta property="og:url" content="https://esthon.com" />
            </Helmet>
            <div className="center">
                <div style={{ display: 'flex' }} >
                    <Link to="/about">{message}</Link>
                    <span 
                        className="blink"
                        onMouseEnter={() => rabbitHover()}
                        onMouseLeave={() => setHover(false)}
                    >_</span>
                </div>
                <div 
                    // className={hover ? "blink" : ""}
                    style={{ textAlign: 'center', opacity: hover ? '100%' : '0%', marginTop: '1em', position: 'absolute', marginTop: '5em' }}
                    onMouseEnter={() => rabbitHover()}
                    onMouseLeave={() => setHover(false)}
                >
                    {glitchedRabbit ? <RabbitSVG /> : charsR[Math.floor(Math.random() * charsR.length)] }
                    {/* {glitchedRabbit ? <RabbitSVG /> : <RabbitSVG2 /> } */}
                </div>
            </div>
            {/* <div style={{ textAlign: 'center' }}>
                <RabbitSVG />
            </div> */}
        </div>
    );
}

export default HelloWorld;