// Resume.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import DefaultHelmet from '../DefaultHelmet';

import Header from './Header';

import Competencies from './Competencies';
import MultiColumn from './Multicolumn';
import JobDescription from './JobDescription';
import Skills from './Skills';
import Education from './Education';
import Languages from './Languages';
import Interests from './Interests';

import './Resume.css';

function Resume(props) {
    return (
      <>
      <DefaultHelmet />
      <Helmet>
        <title>esthon.com - Experience</title>
      </Helmet>
      <div className="resume">
        <div className="back-link">
          <Link to="/about"><FontAwesomeIcon icon={faArrowLeft} /></Link>
        </div>
        <Header 
          name="Esthon Firmo de Medeiros Junior"
          title="Multipurpose IT Engineer | Full Stack Web Developer | Blockchain Integrator | AI Enthusiast"
          location1="132 Rua do Cupim, Gracas"
          location2="Recife, PE, 52011-070, Brazil"
          email="esthon@mej.solutions"
          // phone="+55 81 9 9614 4223"
          linkedin="https://linkedin.com/in/esthon"
          website1="https://mej.solutions"
          website2="https://esthon.com"
        />
        {/* <Competencies items={['JavaScript', 'Node.js', 'React', ]} /> */}
        {/* <div className="multi-column">
        <WorkExperience jobs={[{
          title: "Founder & Multipurpose IT Engineer",
          company: "MEJ Solutions",
          duration: "Jun 2023 - Present",
          duties: ["Established MEJ Solutions..."]
        },]} />
                <div><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nisl et diam dictum venenatis. Nulla a dolor eu turpis ornare posuere. Vivamus iaculis luctus lectus, a consectetur velit ultrices in. Donec maximus nulla eget neque mollis, ut fringilla ligula scelerisque. Aenean imperdiet eros lectus, a blandit massa volutpat nec. In tincidunt lectus mauris, vitae eleifend purus semper non. Maecenas aliquet enim sed mattis hendrerit.</p> </div>
        <div><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nisl et diam dictum venenatis. Nulla a dolor eu turpis ornare posuere. Vivamus iaculis luctus lectus, a consectetur velit ultrices in. Donec maximus nulla eget neque mollis, ut fringilla ligula scelerisque. Aenean imperdiet eros lectus, a blandit massa volutpat nec. In tincidunt lectus mauris, vitae eleifend purus semper non. Maecenas aliquet enim sed mattis hendrerit.</p> </div>
        <div><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nisl et diam dictum venenatis. Nulla a dolor eu turpis ornare posuere. Vivamus iaculis luctus lectus, a consectetur velit ultrices in. Donec maximus nulla eget neque mollis, ut fringilla ligula scelerisque. Aenean imperdiet eros lectus, a blandit massa volutpat nec. In tincidunt lectus mauris, vitae eleifend purus semper non. Maecenas aliquet enim sed mattis hendrerit.</p> </div>
        <div><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nisl et diam dictum venenatis. Nulla a dolor eu turpis ornare posuere. Vivamus iaculis luctus lectus, a consectetur velit ultrices in. Donec maximus nulla eget neque mollis, ut fringilla ligula scelerisque. Aenean imperdiet eros lectus, a blandit massa volutpat nec. In tincidunt lectus mauris, vitae eleifend purus semper non. Maecenas aliquet enim sed mattis hendrerit.</p> </div>
        <div><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nisl et diam dictum venenatis. Nulla a dolor eu turpis ornare posuere. Vivamus iaculis luctus lectus, a consectetur velit ultrices in. Donec maximus nulla eget neque mollis, ut fringilla ligula scelerisque. Aenean imperdiet eros lectus, a blandit massa volutpat nec. In tincidunt lectus mauris, vitae eleifend purus semper non. Maecenas aliquet enim sed mattis hendrerit.</p> </div>
        <div><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nisl et diam dictum venenatis. Nulla a dolor eu turpis ornare posuere. Vivamus iaculis luctus lectus, a consectetur velit ultrices in. Donec maximus nulla eget neque mollis, ut fringilla ligula scelerisque. Aenean imperdiet eros lectus, a blandit massa volutpat nec. In tincidunt lectus mauris, vitae eleifend purus semper non. Maecenas aliquet enim sed mattis hendrerit.</p> </div>
        <div><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nisl et diam dictum venenatis. Nulla a dolor eu turpis ornare posuere. Vivamus iaculis luctus lectus, a consectetur velit ultrices in. Donec maximus nulla eget neque mollis, ut fringilla ligula scelerisque. Aenean imperdiet eros lectus, a blandit massa volutpat nec. In tincidunt lectus mauris, vitae eleifend purus semper non. Maecenas aliquet enim sed mattis hendrerit.</p> </div>
        <div><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed nisl et diam dictum venenatis. Nulla a dolor eu turpis ornare posuere. Vivamus iaculis luctus lectus, a consectetur velit ultrices in. Donec maximus nulla eget neque mollis, ut fringilla ligula scelerisque. Aenean imperdiet eros lectus, a blandit massa volutpat nec. In tincidunt lectus mauris, vitae eleifend purus semper non. Maecenas aliquet enim sed mattis hendrerit.</p> </div>

        </div> */}
        <div className="resume-row">

          <div className="resume-col">
            <h3>Work Experience</h3>
            <JobDescription job={{
              company: "MEJ Solutions, Remote",
              duration: "(Jun/2023 - Present)",
              title: "Founder & Multipurpose IT Engineer",
              duties: [
                "Established MEJ Solutions to formally provide IT services, enhancing business operations and elevating client engagement.",
                "Continue to lead and execute diverse projects with expertise in areas such as Node.js, React, Next.js, Svelte, Blockchain, and more, similar to my freelance work.",
                "Integrated artificial intelligence capabilities into multiple projects, harnessing advanced AI tools to drive innovation and elevate service offerings.",
                "Manage all client relations, project coordination, and technical support, consistently delivering top-quality solutions."
              ]
            }} />
            <JobDescription job={{
              company: "RioBlockchain, Remote",
              duration: "(Oct/2016 - present)",
              title: "Full Stack Web Developer",
              duties: [
                "Led full-stack development, focusing on Blockchain integrations.",
                "Utilized Python, Node.js, and Shell Scripting for diverse projects.",
                "Integrated BSV, BCH, BTC with various cryptocurrency exchanges.",
                "Managed Google Cloud Platform, optimizing virtual environments.",
                "Maintained Linux systems, ensuring performance and security.",
                "Developed WordPress sites with responsive design features."
              ]
            }} />
            <JobDescription job={{
              company: "Freelancer, evolved into MEJ Solutions, Remote",
              duration: "(Jan/2009 - May/2023)",
              title: "Multipurpose IT Engineer",
              duties: [
                "Spearheaded a diverse range of IT projects, showcasing expertise in areas such as Node.js, React, Next.js, Blockchain, Ethereum, BSV, MongoDB, PHP, Python, and Shell Scripting, HTML, Vanilla JavaScript.",
                "Pioneered the integration of cutting-edge blockchain technologies (including BSV, Ethereum, and MATIC) into various web projects, significantly enhancing system efficiency, security, and innovation.",
              ]
            }} />
          </div>
          <div className="resume-col">
          <JobDescription job={{
              duties: [
                "Delivered comprehensive IT support across Linux and Windows platforms, solidifying a reputation for technical excellence, problem-solving acumen, and consistent reliability.",
              ]
            }} />
            <JobDescription job={{
              company: "The Arcadia Group",
              duration: "(May/2023 - Jul/2023)",
              title: "Software Engineer",
              duties: [
                "Contributed to a pioneering technology initiative within an international team setting.",
                "Engineered backend structures using Node.js, enhancing overall system performance.",
                "Innovated on the frontend, employing Svelte for optimal user interaction.",
                "Anticipated technological advancements by preparing for Blockchain and AI integrations.",
                "Demonstrated adaptability and resilience in a fluid project landscape.",
                "Upheld strong professional standards and collaborative spirit throughout the engagement."
              ]
            }} />
            <JobDescription job={{
              company: "Nova Solução, Remote",
              duration: "(Dec/2016 - Dec/2017)",
              title: "Sales Engineer",
              duties: [
                "Spearheaded IT solutions sales, engaging with private and governmental sectors.",
                "Conducted technical demonstrations, enhancing client understanding of cutting-edge tools.",
                "Fostered potential client relationships, navigating long sales cycles with strategic insights.",
                "Expanded professional acumen with hands-on experience in complex sales environments.",
                "Represented major brands, including SolarWinds, Quest, Adobe, Microsoft, Veeam, and Invgate, broadening technological expertise.",
              ]
            }} />
            <JobDescription job={{
              company: "IBM, Belo Horizonte/MG",
              duration: "(Sep/2010 - Feb/2015)",
              title: "IT Specialist - Systems Analyst",
              duties: [
                "Integral member of the project’s DevOps team within CPS (System Protection Center), specializing in mainframe client support and system corrections.",
                "Led detailed investigations and root cause analyses, implementing solutions in Cobol, Natural, and JCL.",
              ]
            }} />
          </div>
        </div>
      </div>
      <div className="resume">
        {/* <Competencies items={['JavaScript', 'Node.js', 'React', ]} /> */}
        <div className="resume-row">
          <div className="resume-col">
            <JobDescription job={{
              duties: [
                // "Led detailed investigations and root cause analyses, implementing solutions in Cobol, Natural, and JCL.",
                "Managed end-to-end technical requirements, driving stages from research to deployment.",
                "Undertook “on duty” roles, ensuring system reliability and continuous client support.",
                "Innovated with strategic error mapping and procedural enhancements, optimizing team operations.",
                "Acted as the primary liaison for partners, streamlining communication and collaboration.",
                "Sustained high-performance standards during three years of remote work, demonstrating adaptability.",
                "Mentored team members, enriching their IT proficiency and adherence to company culture."
              ]
            }} />
            <JobDescription job={{
              company: "IBM, Belo Horizonte/MG",
              duration: "(May/2010 - Aug/2010)",
              title: "IT Specialist - Support/Release Management",
              duties: [
                "Managed mainframe project releases, enhancing support and coordination efforts.",
                "Streamlined client communications, optimizing scheduled deployment processes.",
                "Innovated operational practices by introducing automated tasks, boosting efficiency.",
                "Implemented strategic process control methods, elevating overall workflow precision.",
              ]
            }} />
            <JobDescription job={{
              company: "IBM, Belo Horizonte/MG",
              duration: "(Jan/2010 - Apr/2010)",
              title: "Build to Plan",
              duties: [
                "Acquired expertise in Natural, ADABAS, JCL, and Complete through intensive training sessions.",
                "Excelled in technical examinations, standing out for high proficiency.",
                "Demonstrated leadership in a pilot project, steering team towards strategic objectives.",
                "Orchestrated team dynamics, culminating in the successful and timely project execution.",
              ]
            }} />
            <JobDescription job={{
              company: "Fuctura Tecnologia, Recife/PE",
              duration: "(Jan/2008 - Jun/2008)",
              title: "Trainee on IT Consulting",
              duties: [
                "Achieved LPIC-1 certification, excelling in Linux training and subsequently invited to join the staff.",
                "Engaged in IT solution sales and consulting, emphasizing Free Software for diverse client needs.",
                "Provided hands-on Linux support, coupled with client education, enhancing their operational efficiency.",
                "Participated in financial market courses, gaining insights into stock trading and market analysis.",
                "Assumed responsibility for coordinating educational events, broadening outreach and knowledge sharing.",
                "Contributed to IT projects, deploying Free Software solutions in small businesses, including training and support."
              ]
            }} />
            <JobDescription job={{
              company: "Salgado de Oliveira University, Recife/PE",
              duration: "(Jul/2006 - Feb/2007)",
              title: "Coordination assistant",
              duties: [
                "Managed several teams of academic and support staff.",
                "Navigated complex environments, particularly within challenging departments like Law studies.",
                "Assumed comprehensive responsibilities, from staff supervision to academic material management.",
                "Stepped into leadership during coordinator absences, maintaining institutional standards and functionality.",
                "Assumed responsibility for coordinating educational events, broadening outreach and knowledge sharing.",
                "Reflected on professional and personal development, recognizing the value of humility and gratitude."
              ]
            }} />
          </div>
        <div className="resume-col">
          <br />
          <h3>Skills</h3>
          <Skills skills={[{
            title: "Development & Scripting:",
            skills: "JavaScript, Node.js, React, Next.js, Svelte, Python, Shell, Scripting, PHP, Java, Flutter, Electron"
          },]} />
          <Skills skills={[{
            title: "Blockchain & AI:",
            skills: "Blockchain, Ethereum, BSV, Artificial Intelligence"
          },]} />
          <Skills skills={[{
            title: "Web Technologies:",
            skills: "HTML, CSS, Web Design, Responsive Development, SEO, PPC Advertising, Social Media Integration, Ajax, JQuery"
          },]} />
          <Skills skills={[{
            title: "Cloud & Virtualization:",
            skills: "Cloud Computing, Google Cloud, AWS, Hetzner, Docker, Virtualization"
          },]} />
          <Skills skills={[{
            title: "Data Management:",
            skills: "MySQL, MongoDB, PostgreSQL"
          },]} />
          <Skills skills={[{
            title: "Legacy Systems:",
            skills: "Mainframe, Natural, Adabas, COBOL, JCL, Complete"
          },]} />
          <Skills skills={[{
            title: "Miscellaneous:",
            skills: "CMS (WordPress), Chaos Theory, Linux (Former LPI Certified), Windows, Adobe Photoshop"
          },]} />
          <br />
          <h3>Education</h3>
          <Education education={{
            title: "Postgraduate Studies in IT Management and Governance",
            duration: "SENAC, Belo Horizonte, MG – 2016",
            details: [`Final paper: "Cloud Governance: An Approach for Governance on the Cloud Computing Environment." This
            study explored effective strategies for governing IT
            resources in cloud environments, emphasizing security,
            compliance, and operational control.`]
          }} />
          <Education education={{
            title: "Bachelor of Computer Science",
            duration: "FUMEC University, Belo Horizonte, MG – 2013",
            details: [`Final paper: "Chaos Theory and Big Data: An Approach for Predictive Analytics." The paper delved into the application of chaos theory in predictive analytics, offering insights into data pattern complexities and forecasting methods.`]
          }} />
          <br />
          <h3>Languages</h3>
          <Languages language={{
            title: "English: Professional Working Proficiency",
            description: "Able to conduct negotiations, write and present reports, and engage in work discussions effectively and independently",
          }} />
          <Languages language={{
            title: "Portuguese: Native Proficiency",
            description: "Full fluency in social and professional contexts, including reading, writing, listening, and speaking",
          }} />
          <br />
          <h3>Interests</h3>
          <Interests interests={{
            details: [
              "Technology & Blockchain",
              "Philosophy & Stoicism",
              "Creative Writing & Drawing",
              "Chess & Strategic Games",
              "Cycling & Calisthenics",
              "Financial Investing & Markets",
              "Pop Culture & RPGs",
              "Spiritual Growth & Yoga",
            ]
          }} />
        </div>
        </div>
        <div className="back-link-bottom only-mobile">
          <Link to="/about"><FontAwesomeIcon icon={faArrowLeft} /></Link>
        </div>
      </div>
      </>
    );
  }

export default Resume;
