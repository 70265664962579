import React from 'react';

function JobDescription(props) {
    return (
      <div className="job-description">
        <p>{props.job.company}</p>
        <p>{props.job.duration}</p>
        <h4 className="resume-h4">{props.job.title}</h4>
        <ul>
          {props.job.duties.map((duty, index) => <li key={index}>{duty}</li>)}
        </ul>
      </div>
    );
}

export default JobDescription;