// Seu novo componente Helmet
import React from 'react';
import { Helmet } from 'react-helmet';

const DefaultHelmet = () => (
    <Helmet>
        <meta property="og:title" content="esthon.com" />
        <meta property="og:description" content="Welcome to the digital realm of Esthon Jr, a seasoned IT Engineer specializing in Web Development, Blockchain, and AI. Discover projects, insights, and the journey of transforming ideas into effective digital solutions." />
        <meta property="og:image" content="https://esthon.com/og.png" />
        <meta property="og:url" content="https://esthon.com" />
    </Helmet>
);

export default DefaultHelmet;